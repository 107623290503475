import React, { useState ,useEffect} from "react";
   import {
    Link,
    useNavigate
} from "react-router-dom";
import { Rating } from 'react-simple-star-rating'

 import Header from '../includes/Header';

 import Sidebar from "../includes/Sidebar";

 

  import {userIcon ,streamingIcon,angleLeft} from '../ImagesIcons.js';



import {showLoader,frontUrl, SiteLogo,SiteLogoDark,AkuserId,Url  ,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert,UrlSplitter} from '../Frontapi.js';


 



 
const Mystreams = () => {

const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

const username = UrlSplitter(2);

const [Userprofilepicture] = useState({'preview':SiteLogoDark});


  const [streamsList, setstreamsList] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage,setrecordsPerPage] = useState(10);



 const [showmystreams,setShowmystreams] = useState(1);
 const [showreviews,setShowreviews] = useState(0);



 const [reviews,setreviews] = useState([]);





 const [checkrecordsLength,setcheckrecordsLength] = useState('Loading...')



const GetStreams = async (value ) => {
showLoader(true);

 
 
 

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

   value = (typeof value !== 'undefined') ? value : recordsPerPage;

      var postdata={'currentuser':1,'perpage':recordsPerPage ,'checkuserid':AkuserId};

        const response = await fetch(Url+"getstreams", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

if(data.data.length == 0){
   setcheckrecordsLength('No Streams found')

   setstreamsList([]);
   return false
}


var postdatavalues= data.data;
 
  
  
   
/*

var difference = (new Date().getTime() / 1000 ) - postdatavalues[key].createddatetime;
var minutesDifference = Math.floor(difference/60);
var hoursDifference = Math.floor(difference/3600 );
var daysDifference = Math.floor(difference /86400 );
var  MonthDifference = Math.floor(difference /2620800 );
var  YearDifference = Math.floor(difference /31449600  );

if(parseInt(YearDifference) > 0 ){
  postdatavalues[key].timediff=YearDifference +' years ago';

 }else if(parseInt(MonthDifference) > 0 ){
postdatavalues[key].timediff=MonthDifference +' months ago';
}else if(parseInt(daysDifference) > 0 ){
postdatavalues[key].timediff=daysDifference +' days ago';
}else if(parseInt(hoursDifference) > 0 ){
postdatavalues[key].timediff=hoursDifference +' hours ago';
}else if(parseInt(minutesDifference) > 0 ){
postdatavalues[key].timediff=minutesDifference +' minutes ago';
}
*/
    setstreamsList(postdatavalues);

 


 

 

   
 

    
          } else{
                 
          }

           
       })


 
  };



 const DeleteStream = async (id) => {

    if(window.confirm("Are you sure?")){
        var postdata={'id' : id , userid:AkuserId };
        const response = await fetch(Url+"deletestream", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
                   

          if (data.success) { 
               
            Notifyalert('success', data.message)
            GetStreams();

        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
                 
          }

           
        })

    }

     


  };


     const showReviews = async (id) => {

      setShowreviews(1)
      setShowmystreams(0)

            var postdata = {
                 streamid: id 
            }
            const response = await fetch(Url + "get-stream-reviews", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var postdata = data.data;
                       
                        setreviews(postdata.reviews)
                   
                     
                    } else {}
                })
        }


     const toggleshowMystreams = async () => {

    setShowreviews(0)
      setShowmystreams(1)

    }








    useEffect(() => {
         window.scrollTo(0,0);

    GetStreams();

  
    }, [recordsPerPage]);



 
 
  return (
<div>
   <Header pageInfo="My Streams" />


   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar   />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">

 { (showmystreams==1) ? 


            <div className="container-mobile">






               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > 
                     <span className="goto-section" ><Link to='/livestreams' > <img src={angleLeft} />  </Link>  </span> My Streams
                   </h1>
               </div>
               <div className="live-info-tabs" >
                  <ul className="bg-transparent">
                     <li    >  </li>
                      


                      <li className="add-stream-option"   > <Link to='/add-stream' className="add-stream-option-btn" >Add Stream</Link>  </li>

                  </ul>
               </div>

               { (streamsList.length==0) ? <div className="no-records"> {checkrecordsLength} </div>  :
               <div className="stream-posts">
             {streamsList.map((stream) => (
                  <div className="stream-post-single">
                     <div className="stream-post-user-info col-12"  >
                        <span className="stream-post-user-name"><div className="stream-post-user-img-sec"> 
                         <img src={stream.userprofilepicture} />   <span>{stream.username}</span> </div> </span>



                    {(stream.status == 1 )   ?  
                        <span className="stream-post-user-type stream-option-btn-flex">

                         <Link  to={frontUrl+'edit-stream/'+ stream._id} className="stream-option-btn" >Edit</Link>

                         <a className="stream-option-btn"  onClick={() => DeleteStream(stream._id)}   >Delete</a>  
                      </span> : 'Status : Pending' }



                     </div>


                         <div className="stream-post-info col-12"  >
                        <span className="stream-post-user-name col-6">  <span className="stream-info-datetime"> <small> {stream.startdatetime}  </small> </span>   </span>
                        <span className="stream-post-add-info col-6 text-right"> Standard :  {stream.standard}  </span>
                        </div>


                        <div className="stream-post-info col-12"  >
                        <span className="stream-post-user-name col-6"> Subject :  {stream.subjectname}  </span>
                        <span className="stream-post-add-info col-6 text-right"> Topic :  {stream.topic}  </span>
                        </div>
                        <div className="stream-post-info col-12"  >
                        {stream.caption}  
                        </div>
                        <div className="stream-post-info col-12"  >
                         <a className="stream-meeting-link" href={stream.meetinglink} target="_blank">{stream.meetinglink}</a>
                        </div>


                        {(stream.reviewsexists == 1 )   ?  

                           <div className="stream-post-info col-12"  >
                         <a className="bg-yellow-amigo submit-stream-btn"  onClick={ (streamid)=> showReviews(stream._id) }  target="_blank"> Show Reviews</a>
                        </div>


                         : ''}






                  
                  </div>
                 ))}
              </div>
           }


            </div>

 :''}

 { (showreviews==1) ?    

 <div className="container-mobile">






               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > 
                     <span className="goto-section" ><a  onClick={toggleshowMystreams} > <img src={angleLeft} />  </a>  </span> Reviews
                   </h1>
               </div>



  {reviews.map((reviewsingle) => (

                     <div className="post-reviews-single curs-no">

                       
                      <div className="post-comment-single-user-img" > 
                       <img src={reviewsingle.userprofilepicture } className="post-comment-user-img" /> 

                       <p className="post-comment-user-name" >{reviewsingle.username} </p>

                      </div>
                                            <div className="post-comment-single-user-info" >

                                              
                                             
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> Did you enjoyed the session ? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
         <Rating  initialValue={reviewsingle.favourenjoyed}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> All questions were answered ? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
         <Rating  initialValue={reviewsingle.questionsresponse}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> How was the communication skill? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
         <Rating  initialValue={reviewsingle.communicationskills}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> All topics were covered? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
          <Rating  initialValue={reviewsingle.topicscovered}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> Would you recommend this teacher? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
         <Rating  initialValue={reviewsingle.recommendation}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12">
         <p className=" text-left"> <b>Feedback</b> </p>

         <p>{reviewsingle.feedback} </p>
         
      </div>
   </div>


    
 

                                             </div>


                          


                           
                      </div>

                      ))}




</div>


  :''}







         </div>
      </div>
   </div>
</div>
  );
};
export default Mystreams;