import React, { useState ,useEffect} from "react";
   import {
    Link,
    useNavigate
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Timepicker from 'react-time-picker';  
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { CTimePicker } from '@coreui/react-pro'
import Select from 'react-select';


  
 import Header from '../includes/Header';

 import Sidebar from "../includes/Sidebar";

  import {angleLeft ,calendarIcon,clockIcon} from '../ImagesIcons.js';


import {addStreamfees,entryStreamfees,checkEmptyUndefined,checkUsertypeRedirectIfStudent,GetSubjectsCommon,GetSchoolsCommon,frontUrl,SiteLogo,SiteLogoDark,AkuserId,Url  ,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert,UrlSplitter} from '../Frontapi.js';

const AddLivestream = () => {
        const navigate = useNavigate();
        const [value, onChange] = useState('10:00');
        const [currentDate, setCurrentDate] = useState(new Date());
        const [topic, setTopic] = useState('');
        const [caption, setCaption] = useState('');
        const [meetinglink, setMeetinglink] = useState('');
        const [sessionlanguage, setSessionLanguage] = useState('');
         const [date, setDate] = useState('');
        const [time, setTime] = useState('');
        const [displaydate, setdisplaydate] = useState('');
        const [displaytime, setdisplaytime] = useState('');
        const [showAddsection, setshowAddsection] = useState('1');
        const [showAgreementsection, setshowAgreementsection] = useState('0');
        const [showdatePickeroption, setshowdatePickeroption] = useState(0);
        const [showsubmitButton, setshowsubmitButton] = useState(1);


        const [entrystreamfees , setentrystreamfees]=useState(entryStreamfees)
        const [addstreamfees , setaddstreamfees]=useState(addStreamfees)

        


        const ShowDatepickerSection = (value) => {
            setshowdatePickeroption(value)
        }
        const setDatevalue = (value) => {
            const d = new Date(value).toLocaleDateString('en-US');
            setDate(d)
            var displaydate = new Date(value).toLocaleDateString('en-GB');
            setdisplaydate(displaydate)
            setshowdatePickeroption(0)
        }
        const setTimevalue = (value) => {
            var timevalue = value.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value];
            if (timevalue.length > 1) { // If time format correct
                timevalue = timevalue.slice(1); // Remove full string match value
                timevalue[5] = +timevalue[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                timevalue[0] = +timevalue[0] % 12 || 12; // Adjust hours
            }
            setdisplaytime(timevalue.join(''))
            setTime(value)
        }
        /***Schools only***/
        const [school, setSchool] = useState("");
        const [Schooloptions, setschoolsOptions] = useState();
        const [selectedSchoolOption, setselectedSchoolOption] = useState({
            value: '',
            label: 'Select School*'
        })
        const emptyselectedSchoolOption = () => {
            setselectedSchoolOption({
                value: ' ',
                label: ' '
            })
        }
        const handleChange = (value) => {
            document.getElementsByClassName('select-school-input')[0].classList.remove('notify-input-missing');
            setSchool(value.value);
            setselectedSchoolOption(value)
        }
        /***Schools only***/
        /***Standard only***/
        const [standardsList, setstandardsList] = useState([]);
        const [standard, setStandard] = useState("");
        const [Standardoptions, setstandardOptions] = useState([{
                value: '5',
                label: '5th'
            },
            {
                value: '6',
                label: '6th'
            },
            {
                value: '7',
                label: '7th'
            },
            {
                value: '8',
                label: '8th'
            },
            {
                value: '9',
                label: '9th'
            },
            {
                value: '10',
                label: '10th'
            },
            {
                value: '11',
                label: '11th'
            },
            {
                value: '12',
                label: '12th'
            }
        ]);
        const [selectedStandardOption, setselectedStandardOption] = useState({
            value: '',
            label: 'Select Standard*'
        })
        const emptyselectedStandardOption = () => {
            setselectedStandardOption({
                value: ' ',
                label: ' '
            })
        }
        const handleChangeStandard = (value) => {
            document.getElementsByClassName('select-standard-input')[0].classList.remove('notify-input-missing');
            setStandard(value.value);
            setselectedStandardOption(value)
        }
        /***Standard only***/



        /**Subject***/
        const [subject, setSubject] = useState("");

        const [Subjectoptions, setsubjectsOptions] = useState();
        const [selectedSubjectOption, setselectedSubjectOption] = useState({ value: '', label: 'Select Subject*' }  )

           const  emptyselectedSubjectOption =() => {
          setselectedSubjectOption({ value: ' ', label: ' ' })
        }
        const handleChangeSubject = (value) => {
        document.getElementsByClassName('select-subject-input')[0].classList.remove('notify-input-missing');
        setSubject(value.value);
        setselectedSubjectOption(value)
        }

 

        /**Subject***/



        const Proceedagreementstream = async (e) => {
            e.preventDefault();
            /***Check Required***/
            var required_fields = document.getElementsByClassName('MustEnterField')
            if (document.getElementsByClassName('notify-input-text')[0]) {
                document.getElementsByClassName('notify-input-text')[0].remove();
            }
            var error = 0;
            Array.prototype.forEach.call(required_fields, function(el) {
                el.classList.remove('notify-input-missing');
                el.classList.add('mb-10');
                if (el.value === '' && error == 0) {
                    var inputname = el.name.charAt(0).toUpperCase() + el.name.slice(1);
                    el.classList.add('notify-input-missing');
                    el.classList.remove('mb-10');
                    error = 1;
                    SetNotificationAfterName(el.name, inputname + ' required', 'noneed')
                }
            });
            if (error === 1) {
                return false
            }
            if (date === '') {
                setshowdatePickeroption(1)
                return false
            }
            if (time === '') {
                SetNotificationAfterName('time', 'time' + ' required', 'noneed')
            }



             var postdata =  { 
                            date: date,
                            time: time
                          }

            const response = await fetch(Url + "check-valid-date", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                      postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        
                            setshowAddsection(0)
                            setshowAgreementsection(1)

                    } else {
                        if (typeof data.message != 'undefined') {
                            Notifyalert('error', data.message)
                        }
                    }
                })


           
        }
        const toggleBackAddsection = async (e) => {
            setshowAgreementsection(0)
            setshowAddsection(1)
            showAddsubjectsection(0);
        }


        const [subjectname, setSubjectName] = useState("");
         const [addsubjectsection, showAddsubjectsection] = useState(0);

        const showAddSubject = async (e) => {
              RemoveNotificationAfterName();
            setshowAgreementsection(0);
            setshowAddsection(0);
            showAddsubjectsection(1);
               window.scrollTo(0,0);

        }

          const handleSubjectInput = (e) => {
            RemoveNotificationAfterName();
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
                case 'subjectname':
                    setSubjectName(value);
                    break;
            }
            //  (email != '' && password != '') ? setformValid(true): setformValid(false)
        }
        const Addsubject = async (e) => {
            e.preventDefault();

             /***Check Required***/
            var required_fields= document.getElementsByClassName('MustSubjectEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                        el.classList.add('mb-25');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                        el.classList.remove('mb-25');
                        error=1;

                       /* var element= document.getElementsByName(el.name)[0] ;
                        var requiredelement = document.createElement("div");
                        requiredelement.classList.add('notify-input-text');
                        requiredelement.innerHTML= inputname + ' required'
                        el.after(requiredelement);
                        element.focus();*/

                        SetNotificationAfterName(el.name, inputname + ' required')
             }
             });
             /***Check Required***/

            if(error === 1){
                    return false
            }


            if (checkEmptyUndefined(subjectname) === false) {
                Notifyalert('error', '* Fields Required')
                return false
            }
            try {
                var postdata = {
                    name: subjectname,
                    status: 1
                };
                const response = await fetch(Url + "updatesubject", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success', data.message)
                            var subjectdata = data.data;
                           // setselectedSubjectOption(subjectdata._id)
                            
                              GetSubjects()
                              
//console.log({ value: subjectdata._id, label:subjectdata.name })

                              setselectedSubjectOption({ value: subjectdata._id, label:subjectname })
                               setSubject(subjectdata._id)


                            toggleBackAddsection();



                        } else {
                            Notifyalert('error', data.message)
                        }
                    })
            } catch (error) {}
        }


        

        const Createnewstream = async (e) => {
            setshowsubmitButton(0)
            /***Check Required***/
            var postdata = {
                userid: AkuserId,
                topic: topic,
                caption: caption,
                meetinglink: meetinglink,
                date: date,
                time: time,
                sessionlanguage: sessionlanguage,
                subject: subject,
                school: school,
                standard: standard
            }
            const response = await fetch(Url + "create-new-stream", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        window.location.href = data.redirect
                        // Notifyalert('success', data.message)
                        //    window.location.href=frontUrl+'livestreams'
                        //  document.getElementById("addstreamform").reset()
                    } else {
                        if (typeof data.message != 'undefined') {
                            Notifyalert('error', data.message)
                        }
                    }
                })
        }



           const GetSubjects = async (value, reset = '') => {

              (async function() { 

             setsubjectsOptions  ( await GetSubjectsCommon())

             })()


          }

        const GetSchools = async (value, reset = '') => {
            (async function() {
                setschoolsOptions(await GetSchoolsCommon())
            })()
        }
        const schooloptionselectbox = (event) => {
            setSchool('')
            emptyselectedSchoolOption()
        }



          const checkUsertypeRedirect = async (value) => {


         await checkUsertypeRedirectIfStudent()

           
        };


        useEffect(() => {

            checkUsertypeRedirect();

            GetSchools();
            GetSubjects();
            document.getElementsByClassName("select-register-input")[0].addEventListener("click", schooloptionselectbox);
        }, []);

  return (

					<div>
   <Header pageInfo="Add Stream" />
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">
            <div className="container-mobile">


 {(addsubjectsection === 1)?  
               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi" onClick={toggleBackAddsection}> <span className="goto-section" >   &lt; </span> Add my Subject Name </h1>
                  <input   type="text" name="subjectname" className="form-control register-input mb-25 MustSubjectEnterField"   placeholder="Subject Name*"  
                     onKeyUp={(e) => handleSubjectInput(e)}
                  required/>
                  <button className="submit-stream-btn bg-yellow-amigo"  onClick={Addsubject}>Add my Subject</button>     
               </div>
               : ''}


{   (showAddsection == 1)  ? 


               <div className="add-section"> 
               <div className="page-container-section"  >
 
                  <h1 className="page-container-title cur-poi"   >
                     <span className="goto-section" >
                        <Link  to='/my-streams' >
                        <img src={angleLeft} /> </Link> 
                     </span>
                     Add New Stream
                  </h1>
               </div>
               <form id="addstreamform" >

                <Select className="form-control   MustEnterField select-register-input select-school-input" 
                name={"school"}
                value={selectedSchoolOption}
                onChange={handleChange}
                options={Schooloptions}
                  /> 


                <Select className="form-control   MustEnterField select-register-input select-standard-input" 
                name={"standard"}
                value={selectedStandardOption}
                onChange={handleChangeStandard}
                options={Standardoptions}
                 />  


  <input    autoComplete="off"   type="text" value={sessionlanguage} name="sessionlanguage" onChange={(e)=>setSessionLanguage(e.target.value)} className="form-control stream-input mb-25 MustEnterField"   placeholder="Session Language"    />
                  <input    autoComplete="off"  value={meetinglink}  type="text" name="meetinglink" onChange={(e)=>setMeetinglink(e.target.value)} className="form-control stream-input mb-25 MustEnterField"   placeholder="Meeting Link"    />

  


  <Select className="form-control   MustEnterField select-register-input select-subject-input" 
                     name={"subject"}
                     value={selectedSubjectOption}
                     onChange={handleChangeSubject}
                     options={Subjectoptions}
                     />


                      <p className="register-add-inst" onClick={showAddSubject}   > Can't find my Subject </p>


                  <input    autoComplete="off"   value={topic}  type="text" name="topic" onChange={(e)=>setTopic(e.target.value)} className="form-control stream-input mb-25 MustEnterField"   placeholder="Topic"    />
 
                  <textarea  autoComplete="off"    type="text" name="caption" onChange={(e)=>setCaption(e.target.value)} className="form-control stream-text-caption mb-25 MustEnterField"   placeholder="Caption"  >{caption}</textarea>

                  <div className="date-time-stream ">
                     <div className="date-picker-section cur-poi" onClick={(value)=>ShowDatepickerSection(1)}  > 
                        <span ><img src={calendarIcon}  />   <span> Select Date</span> </span> 
                     </div> 
                     <div className="date-picker-section"  > 
                        <span className="display_flex" ><img src={clockIcon}  /> <span className="clock-input">      <input value={time} name="time" onChange={(e) => setTimevalue(e.target.value)}  className="time-picker-input MustEnterField" type="time" /> 
                        </span>  </span> 
                     </div>
                  </div>
                  <div className="date-time-picker-section"  >
                     {(showdatePickeroption == 1) ?
                     <div className="date-picker-section"  >
                        <DatePicker dateFormat="MMMM d, yyyy" onChange={(date) =>
                        setDatevalue(date)}    minDate={currentDate}
                        inline selected={date}    />
                     </div>
                     :''}
                     <div className="time-picker-section"  > 
                     </div>
                  </div>
                  {(displaydate != '' ) ?  
                  <p className="live-stream-select-date">  Selected Date & Time :  <span>  {displaydate} </span><span>  {displaytime} </span></p>
                  : '' }
                  <button onClick={Proceedagreementstream}  className=" me-1 mb-1 submit-stream-btn next-proc-btn bg-yellow-amigo"   >Next</button>
               </form>
                </div> : '' }



{   (showAgreementsection == 1)  ? 


               <div className="agreement-section"> 



               <div className="page-container-section"  >
 
                  <h1 className="page-container-title cur-poi"   >
                     <span className="goto-section" onClick={toggleBackAddsection} >
                       
                        <img src={angleLeft} /> 
                     </span>
                      Agreement
                  </h1>
               </div>


               <div className="stream-agreement"> 

<p><span className="stream-highlight">I promise I will complete my stream even if number  of
kids are less, during this session I will only teach 
academic lessons. I will follow all rules and 
regulations of</span> <b className="stream-author">Fststeps Pvt. Ltd.</b></p>

<h5>Rules and Regulations</h5>
<ol>
<li> Only Academic content</li>
<li> No comments on Religion, Caste or Nationality</li>
<li> No abusive language </li>
<li> I have right to advertise my classes or tuitions</li>
<li> Sessions will be between 30 min to 45 min.</li>
<li>Fstetps Pvt. Ltd., is only providing platform and they
are not responsible for my actions.</li>
</ol>

<h5>Payments</h5>
<ol>

<li> On my behalf Fststeps Pvt. Ltd.,  will collect the fee 
from students and after session </li>
<li> I understand Fststeps Pvt. Ltd., is the aggregator and
they will charge 30% commission fee. </li>
<li> Per student will be charged Rs. {entrystreamfees}/- for per session.</li>
<li> I will pay Rs.{addstreamfees}/- deposit amount which is 
refundable only after my session is completed.</li>
</ol>


<h5>Important Point to be considered</h5>
<ol>

<li> Number of students count will be shared with me 
under My stream</li>
<li> It is my duty to check on other students are allowed 
to attend my class without payments.</li>
</ol>

               </div>


 {(showsubmitButton == 1) ? 

             <button onClick={Createnewstream}  className=  "me-1 mb-1 submit-stream-btn agree-proc-btn bg-yellow-amigo"    >I agree</button>
: 
             <button    className={ "me-1 mb-1 submit-stream-btn agree-proc-btn bg-yellow-amigo  disabled-btn" }  >I agree</button>


}


               </div> : ''}





            </div>
         </div>
      </div>
   </div>
</div>
                     )


}



export default AddLivestream;